"use client";

// Next
import Link from "next/link";

// Next Intl
import { useTranslations } from "next-intl";

// Components
import { IconBulb, IconDashboard, IconFilter } from "@/components/icons";
import Button from "@/components/Button";
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";

const arrayNav = [
  {
    title: "Client Feedback Form",
    desc: "Access form where clients can create feedback tickets",
    link: "feedback",
    icon: <IconDashboard className="w-10 h-10 text-blue-500" />,
  },
  {
    title: "Back-end status",
    desc: "Access the status of the API Endpoints, and generate default tokens",
    link: "status-view",
    icon: <IconFilter className="w-10 h-10 text-green-500" />,
  },
  {
    title: "Documentation",
    desc: "Dive into the full documentation from components, techstack, and tests",
    link: process.env.NEXT_PUBLIC_API_DOCUMENTATION,
    icon: <IconBulb className="w-10 h-10 text-yellow-500" />,
  },
];

export default function Landing({
  params: { locale },
}: {
  params: { locale: string };
}) {
  const t = useTranslations("Landing");

  return (
    <main className="max-w-screen h-full lg:h-screen flex items-center justify-center bg-gray-200 flex-col">
      <article className="max-w-screen flex flex-col">
        <div className="md:absolute top-0 left-0">
          <Navbar
            fqcn_bui={{
              Bundle: "statusBundle",
              Unit: "navbar",
              Interface: "status",
            }}
          />
        </div>

        <div className="pb-20">
          <div className="text-center pt-5">
            <h1 className="text-2xl font-bold">
              {t("Welcome to PHPReaction Ticket Feedback")} 👋
            </h1>
            <p>{t("Where would you like to go?")} 👇</p>
          </div>

          <div className="pt-12 grid grid-cols-1 gap-8 md:grid-cols-3 md:p-12 justify-center place-items-center">
            {arrayNav.map((item) => (
              <div
                className="border-2 border-gray-300 bg-white hover:border-blue-400 transform hover:scale-[1.02] transition-all ease-linear rounded-lg p-3 hover:shadow-xl duration-300"
                key={arrayNav.indexOf(item)}
              >
                <Link
                  href={
                    item?.link?.includes("http")
                      ? item?.link
                      : locale === "fr"
                      ? `/fr/${item?.link}`
                      : `/en/${item?.link}`
                  }
                >
                  <div className="flex w-60 h-64 flex-col items-center justify-between text-center">
                    <div className="flex flex-col gap-5 mt-4">
                      <div className="flex justify-center items-center">
                        {item.icon}
                      </div>
                      <h3>{t(item.title)}</h3>
                      <p className="text-sm p-3">{t(item.desc)}</p>
                    </div>
                    <Button className="h-8">{t("Visit")}</Button>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </article>

      <Footer
        fqcn_bui={{
          Bundle: "homeBundle",
          Unit: "footer",
          Interface: "landing",
        }}
        footerStyles="lg:absolute"
      />
    </main>
  );
}
